import config from "config";
import { getHeaders, isError, send } from "services/commons.api";
import { PlayerCardListResponse } from "./player-card.model";
import { DateTime } from "luxon";

export const getPlayerCards = ({teamId, playerId, eventId, from, to }: {teamId: string, eventId?: string, playerId?: string, from?: number, to?: number}) => 
  (token: string) => () =>
    send<PlayerCardListResponse>({
      apiEndpoint: config.API.CARDS.GET_LIST,
      headers: getHeaders(token),
      urlParams: {
        teamId,
        playerId: playerId || "",
        eventId: eventId || "",
        from: from || DateTime.now().minus({days: 14}).toMillis(),
        to: to || DateTime.now().toMillis(),
      },
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });
