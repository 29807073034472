import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Typography, Button, TextField, Grid } from "@mui/material";

import { getHeaders, isError, isOkResponse, send } from "services/commons.api";
import { User } from "store/user";
import { onNameChange } from "components/utils/utils";
import { redirectToAuth } from "utils/ui-utils";
import { Trans } from "@lingui/macro";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";
import config from "../../config";
import { getToken } from "providers/auth";
import { useAnalytics } from "use-analytics";

const JoinTeamView = () => {
  const analytics = useAnalytics();
  const [submitEnabled, setSubmitEnabled] = useState(Boolean(false));
  const [profileFilled, setProfileFilled] = useState(false);
  const { teamId } = useParams<{ teamId: string }>();
  const [loading, setLoading] = useState(false);
  const [fnError, setFnError] = useState("");
  const [lnError, setLnError] = useState("");
  const [firstName, setFn] = useState("");
  const [lastName, setLn] = useState("");
  const history = useHistory();
  const token = getToken().jwtToken || "";

  useEffect(() => {
    analytics.track("join-team-init", { teamId });
  }, []);

  const getUserFromStateOrRemote = async () => {
    if (profileFilled) {
      return;
    }

    const info = await send<User>({
      apiEndpoint: config.API.USER.GET,
      headers: getHeaders(token),
    });

    if (isError(info)) {
      return {} as User;
    }

    setProfileFilled(Boolean(info.data.firstName && info.data.lastName));
    return info.data;
  };

  const saveProfile = async () => {
    try {
      const resp = await send({
        apiEndpoint: config.API.USER.PATCH,
        headers: getHeaders(token),
        body: {
          firstName,
          lastName,
        },
      });

      if (isOkResponse(resp)) {
        analytics.track("join-team-profile-updated", { firstName, lastName });
        return true;
      } else {
        if (resp.status === 409) {
          alert("Please create ADIDAS TEAM FX account first using mobile app!");
          analytics.track("join-team-profile-error-no-account", { firstName, lastName });
          history.push("/logout");
        } else {
          setFnError("Failed to save data. Try again later.");
          setLnError("Failed to save data. Try again later.");
          analytics.track("join-team-profile-error", { firstName, lastName, resp });
        }
      }
    } catch (err) {
      setFnError("Failed to save data. Try again later.");
      setLnError("Failed to save data. Try again later.");
      analytics.track("join-team-profile-error", { firstName, lastName, err });
    }

    return false;
  };

  const submit = async () => {
    setLoading(true);
    let saveProfileResult = true;
    if (!profileFilled) {
      saveProfileResult = await saveProfile();
    }

    if (saveProfileResult) {
      const resp = await send({
        apiEndpoint: config.API.USER_TEAMS.JOIN_TEAM,
        urlParams: { teamId },
        headers: getHeaders(token),
      });

      if (!isError(resp)) {
        analytics.track("join-team-success", { teamId });
        toast.success("You have sent request to join the team.", {
          autoClose: 10000,
        });
        history.push(`/teams/${teamId}/dashboard`);
      } else {
        analytics.track("join-team-error", { teamId });
        toast.error("Failed to send request to join the team.", {
          autoClose: 10000,
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileFilled || (firstName && lastName && !fnError && !lnError)) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [firstName, lastName, fnError, lnError, profileFilled]);

  const getHeader = (teamName: string): string =>
    `Joining the team: '${teamName}'.`;

  const query = new URLSearchParams(window.location.search);
  const teamName = query.get("teamName") || "No Name Available";

  useEffect(() => {
    if (!token) {
      redirectToAuth(history);
    } else {
      getUserFromStateOrRemote();
    }
  });

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          <Grid container direction="column" spacing={4}>
            <Grid item width="fit-content">
              <Typography variant="h3">{getHeader(teamName)}</Typography>
            </Grid>
            <Grid item>
              {profileFilled ? (
                ""
              ) : (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="body1">
                      <Trans>
                        Please enter your first and last name to proceed.
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="filled"
                      label={"First Name"}
                      onChange={onNameChange(setFnError, setFn)}
                      helperText={fnError}
                      error={Boolean(fnError)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="filled"
                      label={"Last Name"}
                      onChange={onNameChange(setLnError, setLn)}
                      helperText={lnError}
                      error={Boolean(lnError)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={!submitEnabled || loading}
                onClick={submit}
                style={{ height: 60 }}
                fullWidth
              >
                {!loading ? <Trans>Join Team</Trans> : <Trans>Stand by</Trans>}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default JoinTeamView;
