import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Switch,
  List,
  ListItem,
  Collapse,
  Checkbox,
  Stack,
  ListItemButton,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { font } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import "./filter-modal.style.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { PositionFilter } from "./position-models";
import TooltipCustom from "components/tooltip.component";
import {
  PlayerPosition,
  PlayerPositionFullTitle,
} from "@gamer/common/lib/models/user";
import CFGoalSelected from "images/pitch/CFGoalSelected.svg";
import CFGoalDefault from "images/pitch/CFGoalDefault.svg";
import CFGoalHover from "images/pitch/CFGoalSelected.svg";
import CBGoalSelected from "images/pitch/CBGoalSelected.svg";
import CBGoalDefault from "images/pitch/CBGoalDefault.svg";
import CBGoalHover from "images/pitch/CBGoalHover.svg";
import LRMSelected from "images/pitch/LRMSelected.svg";
import LRMDefault from "images/pitch/LRMDefault.svg";
import LRMHover from "images/pitch/LRMHover.svg";
import CMSelected from "images/pitch/CMSelected.svg";
import CMDefault from "images/pitch/CMDefault.svg";
import CMHover from "images/pitch/CMHover.svg";
import { Plural, Trans } from "@lingui/macro";

export type FilterItemProps = {
  title: string;
  value: string;
  onClick: (value: string) => void;
  selected: boolean;
};

export type FilterValue = {
  value: string;
  title?: string;
  groupName: string;
};

export type FilterComponentProps = {
  title: string;
  label: (props: FilterItemProps) => ReactNode;
  open: boolean;
  options: FilterValue[];
  filter: PositionFilter;
  onSave: (filter: PositionFilter) => void;
};

export const PositionFilterModalNew = (props: FilterComponentProps) => {
  const { open } = props;
  const [values, setValues] = useState(new Set<string>(props.filter.positions));
  const [notDefinedEnbled, setNotDefinedEnbled] = useState(
    props.filter.includeNotDefined,
  );

  const groups = props.options.reduce(
    (acc, { groupName, ...rest }) => ({
      ...acc,
      [groupName]: acc[groupName] ? [...acc[groupName], rest] : [rest],
    }),
    {} as Record<string, Omit<FilterValue, "groupName">[]>,
  );
  const groupNames = Object.keys(groups);
  const [posListOpen, setPosListOpen] = useState<{ [id: string]: boolean }>(
    groupNames.reduce((prev, group) => {
      prev[group] = false;
      return prev;
    }, {}),
  );

  const isAllGroupSelected = (group: string) =>
    groups[group].every((v) => values.has(v.value));

  const isAllSelected = () =>
    Object.keys(groups).every((groupName) => isAllGroupSelected(groupName));

  const isAnyGroupSelected = (group: string) =>
    groups[group].some((v) => values.has(v.value));

  const isAnySelected = () =>
    Object.keys(groups).some((groupName) => isAnyGroupSelected(groupName));

  const handleClose = () => {
    setValues(new Set(props.filter.positions));
    props.onSave({
      positions: props.filter.positions,
      includeNotDefined: notDefinedEnbled,
    });
  };

  const handleApply = (values: string[]) => {
    props.onSave({ positions: values, includeNotDefined: notDefinedEnbled });
  };

  const handleReset = () => {
    const resetVal = new Set(values);
    resetVal.forEach((val) => resetVal.delete(val));

    setValues(resetVal);
  };

  const onItemClick = (value: string) => {
    const result = new Set(values);

    result.has(value) ? result.delete(value) : result.add(value);

    setValues(result);
  };

  const handleGroupChange = (group: string) => () => {
    const result = new Set(values);

    isAllGroupSelected(group)
      ? groups[group].forEach((v) => result.delete(v.value))
      : groups[group].forEach((v) => result.add(v.value));

    setValues(result);
  };

  const handleAllGroupChange = () => () => {
    const result = new Set(values);

    isAllSelected()
      ? Object.keys(groups).forEach((groupName) =>
          groups[groupName].forEach((v) => result.delete(v.value)),
        )
      : Object.keys(groups).forEach((groupName) =>
          groups[groupName].forEach((v) => result.add(v.value)),
        );

    setValues(result);
  };
  const pitchPositionSpacing = 0.5;
  const pitchPosHeight = 65;
  const pitchPosWidth = 90;
  const bgPosColor = "#262626";
  const getTextColor = (pos: PlayerPosition) =>
    values.has(pos) ? "#51F69D" : "#B3B3B3";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      scroll="body"
      style={{
        height: 700,
        backgroundImage: "unset",
      }}
      className="filterModal-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid item>
            <Grid
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="h5">
                  <Trans>Filter Positions</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="#B3B3B3"
                  fontStyle="normal"
                  fontSize={12}
                  fontFamily={font.adineue.cond}
                >
                  <Trans>Select the positions you want to display</Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <Stack direction="row" style={{ width: "100%" }} spacing={8}>
              <Stack
                direction="column"
                style={{ width: "40%", height: "410px", overflow: "auto" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Stack direction="row" alignItems={"center"}>
                    <Checkbox
                      checked={isAllSelected()}
                      indeterminate={!isAllSelected() && isAnySelected()}
                      onClick={handleAllGroupChange()}
                    />
                    <Typography
                      fontSize="16px"
                      fontFamily={font.adihaus.regular}
                    >
                      <Trans>Select All</Trans>
                    </Typography>
                  </Stack>
                  <Typography fontSize="12px" fontFamily={font.adihaus.regular}>
                    <Plural value={values.size} other="# SELECTED" />
                  </Typography>
                </Stack>
                <List>
                  {groupNames.map((groupName) => (
                    <div>
                      <ListItem
                        style={{ width: "100%", padding: "0px 0px 0px 0px" }}
                      >
                        <Checkbox
                          checked={isAllGroupSelected(groupName)}
                          indeterminate={
                            !isAllGroupSelected(groupName) &&
                            isAnyGroupSelected(groupName)
                          }
                          onClick={handleGroupChange(groupName)}
                        />
                        <ListItemButton
                          onClick={() => {
                            setPosListOpen({
                              ...posListOpen,
                              [groupName]: !posListOpen[groupName],
                            });
                          }}
                          style={{ width: "100%", padding: "0px 0px 0px 0px" }}
                        >
                          <Stack
                            direction="row"
                            alignContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              fontSize="16px"
                              fontFamily={font.adihaus.regular}
                              style={{ width: "100%" }}
                            >
                              {groupName}
                            </Typography>
                            {posListOpen[groupName] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Stack>
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={posListOpen[groupName]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {groups[groupName].map(({ title, value }) => (
                            <div>
                              <ListItem style={{ padding: "0px 0px 0px 10px" }}>
                                <Checkbox
                                  checked={values.has(value)}
                                  onClick={() => onItemClick(value)}
                                />
                                <Typography
                                  fontSize="16px"
                                  fontFamily={font.adihaus.regular}
                                >
                                  <Trans>
                                  {title}
                                  </Trans>
                                </Typography>
                              </ListItem>
                            </div>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Stack>
              <Stack
                direction="column"
                spacing={2 * pitchPositionSpacing}
                style={{ height: "410px" }}
              >
                {/* OFFENSE */}
                <Stack
                  id="offenseRow"
                  direction="row"
                  spacing={pitchPositionSpacing}
                  style={{ width: "100%" }}
                >
                  <Stack
                    direction="column"
                    id="leftOffense"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.LF]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LF)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LF),
                        }}
                      >
                        <Trans>LF</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.LW]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LW)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LW),
                        }}
                      >
                        <Trans>LW</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack
                    direction="column"
                    id="centerOffense"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="top"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.CF]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CF)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundImage: `url(${
                            values.has(PlayerPosition.CF)
                              ? CFGoalSelected
                              : CFGoalDefault
                          })`,
                          backgroundPosition: "top",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CF),
                        }}
                      >
                        <Trans>CF</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="bottom"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.ST]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.ST)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.ST),
                        }}
                      >
                        <Trans>ST</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack
                    direction="column"
                    id="rightOffense"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.RF]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RF)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RF),
                        }}
                      >
                        <Trans>RF</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.RW]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RW)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RW),
                        }}
                      >
                        <Trans>RW</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Typography
                    sx={{
                      textOrientation: "sideways",
                      writingMode: "vertical-lr",
                      textAlign: "center",
                    }}
                  >
                    <Trans>

                    OFFENSE
                    </Trans>
                  </Typography>
                </Stack>
                {/* MIDFIELD */}
                <Stack
                  id="midfieldRow"
                  direction="row"
                  spacing={pitchPositionSpacing}
                >
                  <Stack
                    direction="column"
                    id="leftMid"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.LM]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LM)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: "100%",
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${
                            values.has(PlayerPosition.LM)
                              ? LRMSelected
                              : LRMDefault
                          })`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          color: getTextColor(PlayerPosition.LM),
                        }}
                      >
                        <Trans>LM</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack
                    direction="column"
                    id="centerMid"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="top"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.CAM]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CAM)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: (2 * pitchPosHeight) / 3,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CAM),
                        }}
                      >
                        CAM
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.CM]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CM)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: (2 * pitchPosHeight) / 3,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${
                            values.has(PlayerPosition.CM)
                              ? CMSelected
                              : CMDefault
                          })`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          color: getTextColor(PlayerPosition.CM),
                        }}
                      >
                        <Trans>CM</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="bottom"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.CDM]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CDM)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: (2 * pitchPosHeight) / 3,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CDM),
                        }}
                      >
                        <Trans>CDM</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack
                    direction="column"
                    id="rightMid"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.RM]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RM)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: "100%",
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${
                            values.has(PlayerPosition.LM)
                              ? LRMSelected
                              : LRMDefault
                          })`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          color: getTextColor(PlayerPosition.RM),
                        }}
                      >
                        <Trans>RM</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Typography
                    sx={{
                      textOrientation: "sideways",
                      writingMode: "vertical-lr",
                      textAlign: "center",
                    }}
                  >
                    <Trans>
                    MIDFIELD
                    </Trans>
                  </Typography>
                </Stack>
                {/* DEFENSE */}
                <Stack
                  id="defenseRow"
                  direction="row"
                  spacing={pitchPositionSpacing}
                >
                  <Stack
                    direction="column"
                    id="leftDefense"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.LWB]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LWB)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LWB),
                        }}
                      >
                        <Trans>LWB</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.LB]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LB)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LB),
                        }}
                      >
                        <Trans>LB</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack
                    direction="column"
                    id="centerDefense"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="top"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.CB]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CB)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CB),
                        }}
                      >
                        <Trans>CB</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="bottom"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.GK]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.GK)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${
                            values.has(PlayerPosition.GK)
                              ? CBGoalSelected
                              : CBGoalDefault
                          })`,
                          backgroundPosition: "bottom",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          color: getTextColor(PlayerPosition.GK),
                        }}
                      >
                        <Trans>GK</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack
                    direction="column"
                    id="rightDefense"
                    spacing={pitchPositionSpacing}
                  >
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.RWB]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RWB)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RWB),
                        }}
                      >
                        <Trans>RWB</Trans>
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={
                        <Trans>
                          {PlayerPositionFullTitle[PlayerPosition.RB]}
                        </Trans>
                      }
                    >
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RB)}
                        variant="text"
                        style={{
                          width: pitchPosWidth,
                          height: pitchPosHeight,
                          textAlign: "left",
                          borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RB),
                        }}
                      >
                        <Trans>RB</Trans>
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Typography
                    sx={{
                      textOrientation: "sideways",
                      writingMode: "vertical-lr",
                      textAlign: "center",
                    }}
                  >
                    <Trans>

                    DEFENSE
                    </Trans>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Stack direction="column" spacing={1} style={{ width: "660px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Switch
                checked={notDefinedEnbled}
                onClick={() => setNotDefinedEnbled(!notDefinedEnbled)}
              />
            </Grid>
            &nbsp;
            <Grid item>
              <Typography fontFamily={font.adihaus.regular} fontSize={14}>
                <Trans>
                  Include players with undefined position
                </Trans>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" onClick={() => handleReset()}>
                <Trans>Clear All</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => handleClose()}>
                <Trans>Cancel</Trans>
              </Button>
              &nbsp;
              <Button
                disabled={values.size === props.options.length}
                variant="contained"
                onClick={() => handleApply(Array.from<string>(values))}
              >
                <Trans>Apply Filter</Trans>
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
