import { useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Stack,
  Avatar,
  Button,
  CircularProgress
} from "@mui/material";
import { DateTime } from "luxon";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { AutoSuggestedTeamEventModal } from "containers/events/auto-suggested-team-event.modal";
import theme, { font } from "theme";
import { useAuthQuery, useAuthMutation, queryClient } from "providers/auth";
import {
  getUserNotifications,
  updateNotificationStatus,
} from "store/user-notifications/notification.query";
import {
  NotificationStatus,
  UserNotification,
} from "@gamer/common/lib/models/push-notifications";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import Theme from "theme";
import { ReactComponent as MarkReadSvg } from "../../images/MarkRead.svg";
import { ReactComponent as MsgNewSvg } from "../../images/MessageNew.svg";

export const Notifications = () => {
  const analytics = useAnalytics();
  const [notificationListOpen, setNotificationListOpen] = useState(false);
  const [autoSuggestedModalEventId, setAutoSuggestedModalEventId] =
    useState<string>("");
  const notificationQuery = useAuthQuery(
    "userNotifications",
    getUserNotifications,
  );
  const notificationMutation = useAuthMutation(
    "userNotifications",
    updateNotificationStatus,
  );
  const history = useHistory();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const notifications = Array.from(
    notificationQuery.data ? notificationQuery.data.notifications : [],
  )
    .filter((notification) => notification.status !== "ARCHIVED")
    .sort((n1, n2) => n2.createdAt - n1.createdAt);
  const newNotificationsCount = notifications.filter(
    (notification) => notification.status === "NEW",
  ).length;

  const readNotification = (id: string) => {
    notificationMutation.mutate({
      notificationId: id,
      patchReq: { status: NotificationStatus.READ },
    });
  };

  useEffect(() => {
    if (notificationMutation.isSuccess && !notificationMutation.isLoading) {
      queryClient.invalidateQueries("userNotifications");
    }
  }, [notificationMutation.isSuccess, notificationMutation.isLoading]);

  const onAllReadClick = () => {
    const newNotifications = notifications.filter((notification) => notification.status === "NEW");
    
    newNotifications.map((notification) => {markRead(notification.notificationId);});
  };

  const markRead = (notificationId) => {
    readNotification(notificationId);
  };

  const onNotificationClick = (notification: UserNotification) => () => {
    readNotification(notification.notificationId);
    analytics.track("notification-click", notification);
    if (notification?.metadata?.eventId && notification.metadata.teamId) {
      setNotificationListOpen(false);
      history.push(
        `/teams/${notification.metadata.teamId}/timeline?eventId=${notification?.metadata?.eventId}`,
      );
    }
  };

  return (
    <Box>
      <IconButton
        ref={anchorRef}
        onClick={() => setNotificationListOpen(true)}
        disabled={notificationQuery.isLoading}
        className="navigationIcon-button"
      >
        <Badge badgeContent={newNotificationsCount} color="primary">
          <NotificationsNoneIcon style={{ fontSize: 25 }} />
        </Badge>
      </IconButton>
      <Popper
        open={notificationListOpen}
        role={undefined}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        sx={{
          zIndex: 3,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ backgroundColor: theme.background.dialog }}>
              <ClickAwayListener
                onClickAway={() => setNotificationListOpen(false)}
              >
                <MenuList
                  autoFocusItem={notificationListOpen}
                  id="menu-list-grow"
                  sx={{
                    backgroundColor: Theme.background.header,
                    maxWidth: "400px",
                    minWidth: "250px",
                    maxHeight: "665px",
                    overflow: "overlay",
                  }}
                >
                  <MenuItem style={{
                    backgroundColor:"rgb(26,26,26,1)",
                    borderBottomColor: "rgba(153, 153, 153, 1)",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "solid" }}>
                    <Typography
                      fontSize="20px"
                      textTransform="uppercase"
                      fontWeight={"700"}
                      fontStyle={"italic"}
                      fontFamily={font.adihaus.medium}
                      lineHeight={"25px"}
                      letterSpacing={"1px"}
                    >
                      <Trans>
                      Notifications
                      </Trans>
                    </Typography>
                  </MenuItem>
                  {newNotificationsCount > 0 ? (
                    <MenuItem style={{
                      backgroundColor:"rgb(26,26,26,1)" }} >
                        <Stack style={{width: "100%"}} direction="row" alignItems="center" justifyContent="center">
                          {!notificationMutation.isLoading ? (
                            <Button
                              variant="text"
                              size="small"
                              onClick={onAllReadClick}
                            >
                              <Stack direction="row" spacing={2}>
                                <MarkReadSvg/><span><Trans>Mark All as Read</Trans></span>
                              </Stack>
                            </Button>) : (
                            <CircularProgress />
                          )}
                        </Stack>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {notifications.length ? (
                    notifications.map((notification) => (
                      <MenuItem
                        style={{
                          backgroundColor: notification?.status === "NEW" ? "rgba(64, 64, 64, 1)" : "rgb(26,26,26,1)",
                          borderBottomColor: notification?.status === "NEW" ? "rgb(26,26,26,1)" : "rgba(64, 64, 64, 1)",
                          borderBottomWidth: "1px",
                          borderBottomStyle: "solid",
                          paddingLeft: "8px"
                        }}
                        key={notification.notificationId}
                        sx={{
                          p: "12px 16px"
                        }}

                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          width="100%"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <span style={{width: "8px"}}>{ notification?.status === "NEW" 
                              ? <MsgNewSvg width={"8px"} onClick={() => markRead(notification.notificationId)}/>
                              : "" }</span>
                            <Stack direction="row" alignItems="center" onClick={onNotificationClick(notification)}>
                              <Avatar
                                src={notification?.metadata?.teamAvatar as string}
                                sx={{ mr: "16px" }}
                              />
                              <Stack>
                                <Typography
                                  variant="body2"
                                  fontSize="14px"
                                  mb="12px"
                                  whiteSpace="initial"
                                >
                                  {notification.message}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="#999999"
                                  textTransform="uppercase"
                                  style={{ fontSize: 12 }}
                                >
                                  {DateTime.fromMillis(
                                    notification.createdAt,
                                  ).toRelative()}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <Typography variant="body2" style={{ fontSize: 30 }}>
                        <span role="img" aria-label="Cowboy Hat Smile">
                          🤠
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        <Trans>You all set. No unread notifications.</Trans>
                      </Typography>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {autoSuggestedModalEventId && (
        <AutoSuggestedTeamEventModal
          open={Boolean(autoSuggestedModalEventId)}
          autoEventId="123"
          handleClose={() => setAutoSuggestedModalEventId("")}
        />
      )}
    </Box>
  );
};
