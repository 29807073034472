import { createTheme } from "@mui/material/styles";

export const font = {
  adineue: {
    cond: "Adineue Pro Cond",
    bold: "Adineue Pro Cond Bold",
    black: "Adineue Pro Cond Black",
    light: "Adineue Pro Cond Light",
  },
  adihaus: {
    medium: "AdiHausDIN Medium",
    regular: "AdiHausDIN Regular",
    condItalic: "AdihausDIN-CondIta",
  },
};

export const theme = {
  background: {
    primary: "#0B0C0D",
    secondary: "#25272a",
    tertiary: "#222528",
    accent: "#212F3C",
    modal: "#2A2D31",
    dialog: "#1E1F21",
    header: "#1A1A1A",
    actions: "#17191A",
    paper: "#727B841A",
  },
  states: {
    pending: "#98989A",
    rejected: "#EF6B43",
  },
  actions: {
    primary: "#51F69D",
    secondary: "#5189F6",
  },
  textField: {
    focused: "#51F69D",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#98989A",
    subtitle: "#E3E4E6",
  },
  indicators: {
    preferred: "#6679FF",
    positionPreferred: "#3510FE",
  },
  borders: {
    secondary: "#727B84",
  },
  font: {
    regular: font.adineue.cond,
    bold: font.adineue.bold,
    black: font.adineue.black,
    light: font.adineue.light,
  },
};

export const muiTheme = createTheme({
  typography: {
    fontFamily: [font.adineue.cond, font.adihaus.medium].join(","),
    fontSize: 16,
    h1: {
      fontFamily: font.adineue.bold,
      fontSize: 80,
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: font.adineue.cond,
      fontSize: 60,
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: font.adihaus.regular,
      fontSize: 32,
      padding: 8,
      backgroundColor: "#FFFFFF",
      color: "#000000",
      textShadow: "none",
      lineHeight: "32px",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: font.adihaus.condItalic,
      fontSize: 32,
      fontWeight: 700,
    },
    h5: {
      fontFamily: font.adineue.cond,
      fontSize: 32,
      textTransform: "uppercase",
    },
    h6: {
      fontFamily: font.adineue.cond,
      fontSize: 24,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontSize: 20,
      fontFamily: font.adihaus.medium,
    },
    subtitle2: {
      fontSize: "16px",
      fontFamily: font.adihaus.medium,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "22px",
    },
    body1: {
      fontSize: 20,
      fontFamily: font.adihaus.regular,
    },
    body2: {
      fontSize: 16,
      fontFamily: font.adihaus.regular,
    },
    button: {
      fontSize: 18,
      fontFamily: font.adihaus.regular,
    },
    caption: {
      fontSize: 20,
      fontFamily: font.adihaus.regular,
    },
    overline: {
      fontSize: 16,
      fontFamily: font.adihaus.regular,
    },
  },
  shape: {},
  palette: {
    mode: "dark",
    primary: {
      main: theme.actions.primary,
      contrastText: "#000",
    },
    secondary: {
      main: theme.actions.secondary,
      contrastText: "#0B0C0D",
    },
    background: {
      default: "#0B0C0D",
      paper: "#25272a",
    },
    text: {
      primary: theme.text.primary,
      secondary: theme.text.secondary,
      disabled: theme.states.pending,
    },
    error: {
      main: "#FF8484",
    },
    divider: "#222528",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        //TODO: fix ripple effect
        root: {
          fontFamily: font.adihaus.regular,
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "20px",
          letterSpacing: "2px",
          textAlign: "center",
          padding: "12px 24px 10px 24px",
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#B8FED8",
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: "#A0C1FF",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: font.adihaus.regular,
          fontSize: "12px",
          fontWeight: 400,
          letterSpacing: "2px",
          alignItems: "center",
          textTransform: "uppercase",
          padding: "16px 16px 14px 16px",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          "& .MuiSelect-select": {
            display: "inline-flex",
            alignItems: "center",
            py: "15px",
          },
          fontSize: "16px",
          lineHeight: "18px",
          borderRadius: "4px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: font.adihaus.regular,
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.5px",
          textAlign: "left",
          minWidth: "200px",
          backgroundColor: "#262626",
          padding: "8px, 12px, 8px, 12px",
          "&.Mui-selected": {
            color: theme.actions.primary,
            backgroundColor: "#333333",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: font.adihaus.regular,
          fontSize: 16,
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "2px",
          textAlign: "center",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          //styleName: Web/Supporting Text;
          fontFamily: font.adihaus.regular,
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "14px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontFamily: font.adineue.cond,
          "&.MuiInputLabel-shrink": {
            paddingTop: 3,
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "8px",
            letterSpacing: "1.2000000476837158px",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Set the border radius to 0
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #222528 inset", // Change the autofill background color
            WebkitTextFillColor: "#ffffff", // Change the autofill text color
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Set the border radius to 0
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 32,
          borderRadius: 26,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 4,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: "#51F69D",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#51F69D",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: "#404040",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              backgroundColor: "#999999",
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 24,
            height: 24,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#D9D9D9",
            opacity: 1,
            // transition: transitions.create(['background-color'], {
            //   duration: 500,
            // }),
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          "&.Mui-disabled": {
            color: "#CCCCCC",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: font.adihaus.regular,
          backgroundColor: "#1A1A1A",
          fontSize: "32px",
          fontStyle: "italic",
          fontWeight: 700,
          lineHeight: "30px",
          letterSpacing: "1px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#1A1A1A",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px 32px 16px 32px",
          backgroundColor: "#1A1A1A",
          gap: "8px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // Custom scrollbar styles for Paper component
          overflow: "auto", // Ensure scrollbar appears when content overflows
          "&::-webkit-scrollbar": {
            width: "8px", // Adjust scrollbar width
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#2E2E2E", // Adjust track color
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#6B6B6B", // Adjust thumb color
            borderRadius: "4px", // Optional: add border-radius to the scrollbar thumb
            border: "3px solid #2E2E2E", // Reuse styles from theme excerpt
          },
          "&::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595", // Adjust focus color
          },
          "&::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595", // Adjust active color
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595", // Adjust hover color
          },
          "&::-webkit-scrollbar-corner": {
            backgroundColor: "#2E2E2E", // Adjust corner color
          },
          // For Firefox
          scrollbarWidth: "thin",
          scrollbarColor: "#6B6B6B #2E2E2E", // thumb and track color
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.5rem", // Adjust the font size as needed
        },
      },
    },
  },
});

export default theme;
