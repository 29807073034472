import * as React from "react";
import {
  ITeamMemberInfo,
  TeamMemberRoleTitle,
} from "store/team-members/team-members.model";
import { Grid, MenuItem } from "@mui/material";
import { PlayerAvatar } from "components/avatar.component";

export const MPS_TO_KMPH = 3.6;
export const M_IN_KM = 1000.0;

export const getAvatarCapitals = (name = "GMR") =>
  name.replace(/[^A-Z]+/g, "").slice(0, 3);

export const stringToColor = (str = "GMR") => {
  const hash = str
    .split("")
    .reduce((hash, v) => v.charCodeAt(0) + ((hash << 5) - hash), 0);
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    const current = "00" + value.toString(16);
    color += current.substring(current.length - 2);
  }
  return color;
};

export const copyInviteLink = (
  teamId: string,
  teamName = "Name Unavailable",
) => {
  const { protocol, host } = window.location;
  //mobile link example https://www.gmr.adidas.com/join_team?teamId=1ee2cc73-75d3-4e27-8032-0a3d194d8f9b&teamName=LEGENGS
  const link = `${protocol}//${host}/join_team?teamId=${teamId}&teamName=${encodeURI(
    teamName,
  )}`;
  navigator.clipboard.writeText(link);
};

export const getPlayersToCompare = (
  memberId: string,
  teamMembers: ITeamMemberInfo[],
) => {
  const approvedMembers =
    teamMembers.filter(({ status }) => status === "APPROVED") || [];
  const players =
    approvedMembers
      .filter(({ role }) => role === "PLAYER")
      .sort(({ memberInfo: a }, { memberInfo: b }) =>
        a?.firstName && b?.firstName
          ? a.firstName.localeCompare(b.firstName)
          : -1,
      ) || [];
  const coaches = approvedMembers.filter(({ role }) => role === "COACH") || [];

  return [...players, ...coaches].map((member) => (
    <MenuItem
      key={`team-select-menu-item-${member.playerId}`}
      value={member.playerId}
      selected={member.playerId === memberId}
    >
      <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
        <Grid item>
          <PlayerAvatar teamId={member.teamId} playerId={member.playerId} style={{ width: 25, height: 25 }}/>
        </Grid>
        <Grid item>{getInvitedMemberNameOrTag(member)}</Grid>
      </Grid>
    </MenuItem>
  ));
};
export const getInvitedMemberNameOrTag = (invitedMember: ITeamMemberInfo) => {
  return invitedMember.memberInfo?.firstName
    ? `${invitedMember.memberInfo?.firstName} ${invitedMember.memberInfo?.lastName}`
    : invitedMember.memberInfo?.tagId || "tagId is Unavailable";
};

export const getTeamRoleTitle = (role?: string) =>
  role ? TeamMemberRoleTitle[role] : "Unknown";

export const getName = (member: ITeamMemberInfo) =>
  member && member.memberInfo
    ? `${member.memberInfo?.firstName} ${member.memberInfo?.lastName}`
    : "Member Unknown";
