import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { NavigationContext } from "providers/navigation.provider";
import { useContext } from "react";
import { getPlayerCards } from "store/player-card/player-card.query";
import { TeamMemberRole } from "store/team-members/team-members.model";
import Tilt from "react-parallax-tilt";
import {
  PlayerCardTeams,
} from "containers/user/player-card.container";
import { getTeamMembers } from "store/team-members/team-members.query";

export const EventPlayerCards = ({
  eventId,
  startTime,
}: {
  eventId: string;
  startTime: number;
}) => {
  const { currentTeam } = useContext(NavigationContext);
  const from = startTime - 30 * 1000;
  const isAdministarativeRole = currentTeam.role !== TeamMemberRole.PLAYER;
  const cardsQuery = useAuthQuery(
    ["cards", currentTeam.teamId, eventId, currentTeam.playerId, from],
    isAdministarativeRole
      ? getPlayerCards({
          teamId: currentTeam.teamId,
          eventId,
          from,
        })
      : getPlayerCards({
          teamId: currentTeam.teamId,
          playerId: currentTeam.playerId,
          eventId,
          from,
        }),
    {
      enabled: !!currentTeam.teamId,
    },
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", currentTeam.teamId],
    getTeamMembers(currentTeam.teamId),
  );

  if (!cardsQuery.data || !teamMembersQuery.data) {
    return <Skeleton height={400} />;
  }

  const eventsCards = cardsQuery.data.data.filter((c) => c.eventId === eventId);

  if (!eventsCards.length) {
    return (
      <Typography variant="h3">No player cards for this event... 🥺</Typography>
    );
  }

  return (
    <Grid container spacing={2} direction="column">
      <Grid item width="fit-content">
        <Typography variant="h3">Player Cards</Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            py: 1,
            overflow: "auto",
            width: 900,
            height: 700,
            scrollSnapType: "x mandatory",
            "& > *": {
              scrollSnapAlign: "center",
            },
            "::-webkit-scrollbar": { display: "none" },
            padding: 2,
          }}
        >
          {eventsCards.map((card) => (
            <Tilt tiltAxis="y">
              <PlayerCardTeams
                team={currentTeam}
                members={teamMembersQuery.data.teamMembers}
                card={card}
              />
            </Tilt>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
