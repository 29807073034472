import { i18n } from "@lingui/core";
import { detect, fromStorage, fromNavigator } from "@lingui/detect-locale";
import { User } from "store/user";

export const localesToNames = {
  da: "Danish",
  de: "German",
  en: "English",
  es: "Spanish",
  fr: "French",
  it: "Italian",
  ja: "Japanese",
  nb: "Norwegian",
  nl: "Dutch",
  sv: "Swedish",
  tr: "Turkish",
  zh: "Chinese (Mandarin)",
};
export const defaultLocale = "en";
const countryToLocale: Record<string, string> = {
  US: "en", // United States
  GB: "en", // United Kingdom
  FR: "fr", // France
  DE: "de", // Germany
  ES: "es", // Spain
  IT: "it", // Italy
  JP: "ja", // Japan
  CN: "zh", // China
  KR: "ko", // South Korea
  IN: "en", // India
};

// mapping for FAQ
const localeToCountry: Record<string, string> = {
  en: "US", // United States
  fr: "FR", // France
  de: "DE", // Germany
  es: "ES", // Spain
  it: "IT", // Italy
  ja: "JP", // Japan
  ko: "KR", // South Korea
  nl: "NL", // Netherlands
};

export const STORAGE_LANGUAGE_KEY = "language";
export const currentLocale = () =>
  localStorage.getItem(STORAGE_LANGUAGE_KEY) || i18n.locale;

export const defaultCountry = "US";
const getCountryByLocale = (locale: string) => localeToCountry[locale] ?? defaultCountry;

const getLocale = (profile?: User) => {
  const DEFAULT_FALLBACK = () => defaultLocale;
  const detected = detect(
    fromStorage(STORAGE_LANGUAGE_KEY),
    () => (profile ? countryToLocale[profile.country] : undefined),
    fromStorage("lang"),
    fromNavigator(),
    DEFAULT_FALLBACK
  ) || defaultLocale;
  const locale = detected.split("-")[0].slice(0, 2);

  return locale;
};

export const getLangCountry = () => {
  const locale = getLocale();
  const langCountry = `${locale}${getCountryByLocale(locale)}`;
 
  return langCountry;
};

export const initLocale = (profile?: User) => {
  const locale = getLocale(profile);

  dynamicActivate(locale, false);

  return locale;
};

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 * @param save boolean
 */
export async function dynamicActivate(locale: string, save?: boolean) {
  if (!localesToNames[locale]) {
    return dynamicActivate(defaultLocale, true);
  }
  const { messages } = await import(`../locales/${locale}/messages`);
  if (save) {
    localStorage.setItem(STORAGE_LANGUAGE_KEY, locale);
  }
  i18n.load(locale, messages);
  i18n.activate(locale);
};
