import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useAuthQuery } from "providers/auth";
import { getTeams } from "store/user-teams/teams.query";
import { useHistory } from "react-router-dom";
import { getUserProfile } from "store/user/user.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import { HttpResponse } from "services/commons.api";
import { OnboardingLoading } from "./loading.page";
import { useAnalytics } from "use-analytics";

enum OnboardingStage {
  ENHANCE_PROFILE,
  CREATE_TEAM,
  TEAM_ROASTER,
  SELECT_TEAM,
  ACCEPT_INVITE,
  FREE,
  SKIP,
}

const OnboardingEntry = () => {
  const params = useParams();
  const [teamId, setTeamId] = useState("");
  const teamsQuery = useAuthQuery("teams", getTeams);
  const userQuery = useAuthQuery("userProfile", getUserProfile);
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );
  const { data: teams, isStale: teamsIsStale } = teamsQuery;
  const {
    data: user,
    isStale: userIsStale,
    isError: userIsError,
    error: userError,
  } = userQuery;
  const { data: members, isStale: membersIsStale } = teamMembersQuery;
  const [stage, setStage] = useState<OnboardingStage | null>(null);
  const currentTeamId = (params as any).teamId;
  const history = useHistory();
  const analytics = useAnalytics();

  useEffect(() => {
    if (teamsIsStale || userIsStale) {
      return;
    }

    if (user && teams) {
      const nameExists = user.firstName || user.lastName;

      if (!nameExists) {
        return setStage(OnboardingStage.ENHANCE_PROFILE);
      }

      if (!currentTeamId && !teams.totalCount) {
        return setStage(OnboardingStage.CREATE_TEAM);
      }

      const isOpenInvitations = teams.userTeams.find(
        (v) => v.status === "INVITATION_PENDING",
      );

      if (!currentTeamId) {
        if (isOpenInvitations) {
          return setStage(OnboardingStage.ACCEPT_INVITE);
        }

        if (teams.totalCount > 1) {
          return setStage(OnboardingStage.SELECT_TEAM);
        } else {
          setTeamId(teams.userTeams[0].teamId);
        }
      } else {
        setTeamId(currentTeamId);
      }
    }
  }, [teams, user, teamsIsStale, userIsStale, currentTeamId, userError]);

  useEffect(() => {
    if (teamsIsStale || membersIsStale) {
      return;
    }

    if (members) {
      if (members.totalCount > 1) {
        return setStage(OnboardingStage.SKIP);
      } else {
        return setStage(OnboardingStage.TEAM_ROASTER);
      }
    }
  }, [teams, members, teamsIsStale, membersIsStale, teamId]);

  useEffect(() => {
    if (userError) {
      if ((userError as any as HttpResponse).status === 404) {
        analytics.track("user-no-account", { userError});
        alert("Please create adidas TEAM FX account first using mobile app!");
      }
      history.push("/logout");
    }
  }, [userIsError, userError, history]);

  switch (stage) {
  case OnboardingStage.ENHANCE_PROFILE:
    return <Redirect to="/onboarding/profile" />;
  case OnboardingStage.CREATE_TEAM:
    return <Redirect to="/onboarding/team" />;
  case OnboardingStage.TEAM_ROASTER:
    return <Redirect to={`/onboarding/members/${teamId}`} />;
  case OnboardingStage.ACCEPT_INVITE:
    return <Redirect to="/onboarding/invite" />;
  case OnboardingStage.SELECT_TEAM:
    return <Redirect to="/onboarding/select" />;
  case OnboardingStage.FREE:
    return <Redirect to="/onboarding/free" />;
  case OnboardingStage.SKIP:
    return <Redirect to={`/teams/${teamId ? teamId : ""}/dashboard`} />;
  default:
    return <OnboardingLoading message="Please, stand by..." />;
  }
};

export default OnboardingEntry;
