import {
  PlayerPosition,
  PlayerPositionFullTitle,
} from "@gamer/common/lib/models/user";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import theme from "../../theme";
import Edit from "@mui/icons-material/Edit";
import { Trans } from "@lingui/macro";

export type PositionButtonProps = {
  position?: PlayerPosition;
  width?: number;
  disabled?: boolean;
  onClick?(): void;
};

export const PositionButton = (props: PositionButtonProps) => (
  <IconButton
    style={{ borderRadius: 4, padding: 0 }}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    <Box
      style={{
        width: props.width || 400,
        border: `1px solid ${theme.borders.secondary}`,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        fontSize: 13,
        lineHeight: "20px",
        padding: "5px 12px",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="body2">
            {props.position
              ? `${PlayerPositionFullTitle[props.position]} (${props.position})`
              : <Trans>Not defined</Trans>}
          </Typography>
        </Grid>
        <Grid item hidden={props.disabled}>
          <form>
            <IconButton
              style={{ borderRadius: 4, height: 30, width: 30 }}
              disabled={props.disabled}
            >
              <Edit style={{ fontSize: 13 }} />
            </IconButton>
          </form>
        </Grid>
      </Grid>
    </Box>
  </IconButton>
);
