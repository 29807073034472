import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PlayerAvatar, PlayerName } from "components/avatar.component";
import {
  StyledDataGrid,
  getStripedClassNames,
} from "components/table.component";
import {
  getLeaderBoardAsList,
  getPlayerName,
  renderPlayer,
} from "containers/leaderboard/rows-leaderboard";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { PositionFilterModal } from "containers/position/position-filter.modal";
import { PositionFilter } from "containers/position/position-models";
import { useAuthQuery } from "providers/auth";
import { NavigationContext } from "providers/navigation.provider";
import { useContext, useEffect, useState } from "react";
import { getTeamLeaderboard } from "store/team-leaderboard/leaderboard.query";
import { PlayerPosition } from "store/team-members/team-members.model";
import { getTeamMembers } from "store/team-members/team-members.query";
import { MPS_TO_KMPH, M_IN_KM } from "utils/team";
import { EditOutlined } from "@mui/icons-material";
import { PlayerPositionFullTitle } from "@gamer/common/lib/models/user";
import TooltipCustom from "components/tooltip.component";

export type PlayerSelectInputProps = {
  playerIds: string[];
  disabled?: string[];
  onClick: () => void;
};

export const PlayerSelectInput = ({
  playerIds,
  disabled,
  onClick,
}: PlayerSelectInputProps) => {
  const { currentTeam } = useContext(NavigationContext);
  const { teamId } = currentTeam;
  return (
    <Button
      variant="outlined"
      color="secondary"
      style={{ borderColor: "#4C4C4C", paddingLeft: 10, paddingRight: 10 }}
      onClick={onClick}
    >
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="space-evenly"
      >
        {playerIds.map((playerId) => {
          const isDisabled = disabled?.includes(playerId);
          return (
            <Tooltip
              title={
                isDisabled ? (
                  <Typography variant="body2">
                    There is no data for{" "}
                    <PlayerName
                      variant="body2"
                      teamId={teamId}
                      playerId={playerId}
                    />
                  </Typography>
                ) : (
                  <PlayerName
                    variant="body2"
                    teamId={teamId}
                    playerId={playerId}
                  />
                )
              }
            >
              <Grid item>
                <PlayerAvatar
                  playerId={playerId}
                  teamId={teamId}
                  showPosition
                  disabled={isDisabled}
                  style={{ width: 32, height: 32 }}
                />
              </Grid>
            </Tooltip>
          );
        })}
        {[0, 0, 0, 0].slice(playerIds.length, 4).map(() => (
          <Grid item>
            <Box
              style={{
                width: 32,
                height: 32,
                borderRadius: "50%",
                border: "#4C4C4C 2px dotted",
              }}
            />
          </Grid>
        ))}
        <Grid item>
          <IconButton color="primary">
            <EditOutlined />
          </IconButton>
        </Grid>
      </Grid>
    </Button>
  );
};

export type PlayerInLeaderboardTableProps = {
  from: number;
  to: number;
  teamId: string;
  playerIds: string[];
  setPlayerIds: (playerIds: string[]) => void;
};

export const PlayerInLeaderboardTable = ({
  setPlayerIds,
  playerIds,
  from,
  to,
  teamId,
}: PlayerInLeaderboardTableProps) => {
  const MAX_PLAYERS_TO_SELECT = 4;
  const leaderboardQuery = useAuthQuery(
    ["leaderboard", teamId, from, to],
    getTeamLeaderboard({ teamId, from: from.valueOf(), to: to.valueOf() }),
    { enabled: Boolean(teamId) },
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );

  const [showPositionFilterModal, setShowPositionFilterModal] = useState(false);
  const [positionFilter, setPositionFilter] = useState<PositionFilter>({
    positions: [],
    includeNotDefined: true,
  });

  const isLoading = leaderboardQuery.isLoading || teamMembersQuery.isLoading;

    // State for storing window dimensions
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      const handleResize = () => {
        // Update window size
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      // Add event listener
      window.addEventListener("resize", handleResize);
  
      // Cleanup function
      return () => window.removeEventListener("resize", handleResize);
    }, []);

  const columns: GridColDef[] = [
    {
      field: "player",
      headerName: "                 Player",
      renderCell: renderPlayer(() => ({})),
      valueFormatter: getPlayerName,
      disableColumnMenu: true,
      width: 300,
      sortable: false,
    },
    {
      field: "position",
      headerName: "Position",
      minWidth: 200,
      valueGetter: (value, row) =>
        PlayerPositionFullTitle[row.player.position],
    },
    {
      field: "totalDistance",
      headerName: "Distance",
      flex: 1,
      valueFormatter: (value) => 
        value ? `${(Number(value) / M_IN_KM).toFixed(2)}  KM` : "-",
    },
    {
      field: "topRunningSpeed",
      headerName: "Top Running Speed",
      flex: 1,
      valueFormatter: (value) =>
        value ? `${(Number(value) * MPS_TO_KMPH).toFixed(2)}  KM/H` : "-",
    },
    {
      field: "topBallSpeed",
      headerName: "Top Ball Speed",
      flex: 1,
      valueFormatter: (value) => value ? `${Number(value).toFixed(2)} KM/H` : "-",
    },
    {
      field: "countKicks",
      headerName: "Kick Count",
      flex: 1,
      valueFormatter: (value) => value || "-",
    },
    {
      field: "totalDistanceXplos",
      headerName: "Explosiveness Distance",
      flex: 1,
      valueFormatter: (value) => value ? `${Math.round(Number(value))}  M` : "-",
    },
    {
      field: "countXplos",
      headerName: "Explosiveness Count",
      flex: 1,
      valueFormatter: (value) => value || "-",
    },
    // {
    //   field: "totalDistanceSprint",
    //   headerName: "Sprint Distance",
    //   valueFormatter: (params) =>
    //     params.value ? `${Math.round(Number(params.value))}  M` : "-",
    //   flex: 0.25,
    // },
    // {
    //   field: "countSprint",
    //   headerName: "Sprint Count",
    //   valueFormatter: (params) => params.value || "-",
    //   flex: 0.2,
    // },
  ];

  const raw = getLeaderBoardAsList(
    leaderboardQuery?.data?.metrics,
    teamMembersQuery?.data?.teamMembers,
    true,
  );
  const rows = raw.filter(
    (row) =>
      (positionFilter.includeNotDefined && !row?.player?.position) ||
      (positionFilter.positions.length
        ? positionFilter.positions.includes(
            row?.player?.position as PlayerPosition,
          )
        : true),
  );

  return isLoading ? (
    <Skeleton width="100%" height={400} style={{ padding: 0, margin: 0 }} />
  ) : (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontSize: "20px",
                fontStyle: "italic",
                fontWeight: 700,
                lineHeight: "25.76px",
                letterSpacing: "1px",
                textAlign: "left",
              }}
            >
              Select up to {MAX_PLAYERS_TO_SELECT} players
            </Typography>
          </Grid>
          <Grid item>
            <PositionFilterModal
              open={showPositionFilterModal}
              filter={positionFilter}
              onSave={(positionFilter) => {
                setPositionFilter(positionFilter);
                setShowPositionFilterModal(!showPositionFilterModal);
              }}
            />
          </Grid>
          <Grid item id="position-filter">
            <Button
              onClick={() => setShowPositionFilterModal(true)}
              color="primary"
              style={{
                borderRadius: 4,
                height: 50,
              }}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <FilterAltIcon color="primary" style={{ fontSize: 20 }} />
                </Grid>
                <Grid item>
                  {positionFilter.positions.length ? (
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{ textTransform: "none" }}
                    >
                      {positionFilter.positions.slice(0, 5).join(", ") +
                        (positionFilter.positions.length > 5 ? " ..." : "")}
                    </Typography>
                  ) : (
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{ textTransform: "uppercase" }}
                    >
                      Filter by Position
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {!isLoading ? (
          <StyledDataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu={false}
            checkboxSelection
            sx={{
              width: `${windowSize.width - 80}px`,
              boreder: "1px solid white",
              border: "1px solid #262626", // Corrected typo here
              borderRadius: "4px", // Added border radius
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
            }}
            onRowSelectionModelChange={(playersIds) => {
              setPlayerIds(
                playersIds.slice(0, MAX_PLAYERS_TO_SELECT) as string[],
              );
            }}
            rowSelectionModel={playerIds}
            getRowClassName={getStripedClassNames}
            slots={{
              baseCheckbox: (props) => {
                return playerIds.length < MAX_PLAYERS_TO_SELECT ? (
                  <Checkbox {...props} />
                ) : (
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: "1.25rem",
                        },
                      },
                    }}
                    title={`You can compare a maximum of ${MAX_PLAYERS_TO_SELECT} players.`}
                  >
                    <Checkbox {...props} />
                  </Tooltip>
                );
              },
            }}
          />
        ) : (
          <Skeleton height={500} width="100%" />
        )}
      </Grid>
    </Grid>
  );
};
