import { Box, Grid, Paper, Typography } from "@mui/material";
import TeamDefaultIcon from "components/icons/TeamDefaultIcon";
import {
  PlayerAttributes,
  PlayerCard,
} from "store/player-card/player-card.model";
import {
  ITeamMemberInfo,
  PlayerPosition,
  PlayerPositionFullTitle,
} from "store/team-members/team-members.model";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { font } from "theme";

export type PlayerCardComponentProps = {
  avatarUrl: string;
  teamLogoUrl: string;
  firstName: string;
  lastName: string;
  postion: PlayerPosition;
  total: number;
} & PlayerAttributes & { width?: number; height?: number };

export const getAttributeBageColor = (value: number) => {
  if (value < 60) {
    return "#333333";
  }
  if (value < 70) {
    return "#C19F7A";
  }
  if (value < 80) {
    return "#E3E3E3";
  }
  if (value < 90) {
    return "#E6C247";
  }
  if (value <= 100) {
    return "#51F69D";
  }

  return "#333333";
};

export const getAttributeTextColor = (value: number) => {
  if (value < 60) {
    return "#F2F2F2";
  }
  if (value < 70) {
    return "#C19F7A";
  }
  if (value < 80) {
    return "#E3E3E3";
  }
  if (value < 90) {
    return "#E6C247";
  }
  if (value <= 100) {
    return "#51F69D";
  }

  return "#F2F2F2";
};

export const CardAttribute = (props: { label: string; value: number }) => (
  <Grid
    container
    direction="column"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item>
      <svg
        width="34"
        height="38"
        viewBox="0 0 34 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.95 0.721688C17.1047 0.632372 17.2953 0.632372 17.45 0.721688L32.9045 9.64434C33.0592 9.73365 33.1545 9.89872 33.1545 10.0774V27.9226C33.1545 28.1013 33.0592 28.2663 32.9045 28.3557L17.45 37.2783C17.2953 37.3676 17.1047 37.3676 16.95 37.2783L1.49553 28.3557C1.34083 28.2663 1.24553 28.1013 1.24553 27.9226V10.0774C1.24553 9.89872 1.34083 9.73365 1.49553 9.64434L16.95 0.721688Z"
          stroke={getAttributeBageColor(props.value)}
          strokeWidth={1.3}
        />
        <text
          x="50%"
          y="55%"
          textAnchor="middle"
          dominantBaseline="middle"
          fill={getAttributeTextColor(props.value)}
          fontSize="19px"
          fontWeight={700}
          letterSpacing="1px"
          fontFamily={font.adihaus.regular}
        >
          {props.value}
        </text>
      </svg>
    </Grid>
    <Grid item>
      <Typography variant="subtitle2">{props.label}</Typography>
    </Grid>
  </Grid>
);

const baseWidht = 328;
const baseHeight = 516;
export const PlayerCardComponent = (props: PlayerCardComponentProps) => (
  <Paper>
    <Grid
      container
      direction="column"
      width={props.width || baseWidht}
      height={props.height || baseHeight}
      border="thin"
      borderColor={"#333333"}
      borderRadius={"2px"}
      padding={"20px"}
      justifyContent={"space-between"}
      style={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,
                          rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
                      url(${props.avatarUrl}) no-repeat, #000000`,
        backgroundSize: `${baseWidht}px ${baseWidht}px`,
      }}
    >
      <Grid item id="top">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item height={52}>
            <Typography
              variant="h5"
              style={{
                fontFamily: font.adihaus.condItalic,
                fontSize: "72px",
                fontWeight: "700",
                letterSpacing: "1px",
                lineHeight: "40px",
                textAlign: "left",
              }}
            >
              {props.total}
            </Typography>
          </Grid>
          <Grid item width={52} height={52}>
            {props.teamLogoUrl ? (
              <img
                src={props.teamLogoUrl}
                alt="Team Logo"
                width={52}
                height={52}
              />
            ) : (
                <Box 
                width={52} 
                height={52} 
                style={{
                  borderRadius: "26px", 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center" 
                }}>
                <TeamDefaultIcon fill={"white"} fontSize={45} />
                </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="bottom">
        <Grid container direction="column">
          <Grid item>
            <Grid id="name" container direction="column" spacing="2px">
              <Grid item width="fit-content">
                <Typography
                  variant="h3"
                  style={{
                    fontSize: "32px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: "1px",
                    textAlign: "left",
                  }}
                >
                  {props.firstName}
                </Typography>
              </Grid>
              <Grid item width="fit-content">
                <Typography
                  variant="h3"
                  style={{
                    fontSize: "32px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: "1px",
                    textAlign: "left",
                  }}
                >
                  {props.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  style={{
                    //styleName: Mobile/Copy/Mid;
                    fontFamily: font.adihaus.regular,
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20.61px",
                    textAlign: "left",
                  }}
                >
                  {PlayerPositionFullTitle[props.postion]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item paddingBottom={"11px"} paddingTop={"36px"}>
            <Grid container justifyContent="space-around">
              {["DRI", "DEF", "PHY", "PAC", "PAS", "SHO"].map((key) => (
                <Grid item>
                  <CardAttribute
                    label={key}
                    value={props[key as keyof PlayerAttributes]}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

export const PlayerCardTeams = (props: {
  card: Pick<PlayerCard, "playerId" | "position" | "total"> & PlayerAttributes;
  members: Pick<ITeamMemberInfo, "memberInfo" | "position" | "playerId">[];
  team: Pick<IUserTeamInfo, "avatar">;
  width?: number;
  height?: number;
}) => {
  const player = props.members.find(
    (member) => member.playerId === props.card.playerId,
  );

  return (
    <PlayerCardComponent
      {...props.card}
      avatarUrl={player?.memberInfo?.avatar || ""}
      teamLogoUrl={props.team.avatar || ""}
      firstName={player?.memberInfo?.firstName || ""}
      lastName={player?.memberInfo?.lastName || ""}
      postion={props.card.position || player?.position || PlayerPosition.GK}
      width={props.width}
      height={props.height}
    />
  );
};
