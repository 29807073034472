import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Typography, Button } from "@mui/material";
import styled from "styled-components";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";

import { Tab, TabPanel, Tabs } from "components/tabs.component";
import { TeamProfileView } from "../containers/teams/team-profile.tab";
import { TeamMembersView } from "../containers/teams/team-members.tab";
import { getTeams } from "store/user-teams/teams.query";
import { useAuthQuery } from "providers/auth";
import { TeamAvatar } from "components/avatar.component";
import { TeamMemberRole } from "store/team-members/team-members.model";
import {
  getNavigationUrl,
  NavigationContext,
  NavigationTabs,
} from "providers/navigation.provider";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { TeamCardsTab } from "containers/teams/team-cards.tab";

const LeftTopPadding = styled.div`
  padding-top: 25px;
  padding-left: 25px;
`;

export enum TabsTypes {
  TEAM,
  CARDS,
  MEMBERS,
}

export const Settings = () => {
  const history = useHistory();
  const analytics = useAnalytics();
  const navigation = useContext(NavigationContext);
  const { currentTeam } = navigation;
  const { tab: selectedTab } = useParams<{ tab: string }>();
  const { teamId } = currentTeam;
  const [tab, setTab] = useState(Number(selectedTab) || TabsTypes.TEAM);
  const tabSelected =
    new URLSearchParams(window.location.search).get("tabSelected") ||
    TabsTypes.TEAM;
  const [currentTeamName, setCurrentTeamName] = useState("");
  const teamsQuery = useAuthQuery("teams", getTeams);
  const { isSuccess, data: teamsQueryData } = teamsQuery;
  const [userRole, setUserRole] = useState(TeamMemberRole.PLAYER);
  const [playerId, setPlayerId] = useState("");

  const handleTabChange = (_event, value) => {
    setTab(value);
  };

  useEffect(() => {
    if (tabSelected) {
      setTab(TabsTypes[tabSelected]);
    }
  }, [tabSelected]);

  useEffect(() => {
    if (isSuccess) {
      const curTeam = teamsQueryData?.userTeams.find(
        (v) => v.teamId === teamId,
      );
      setCurrentTeamName(
        currentTeam.teamName || curTeam?.teamName || "No Name Available",
      );
      setPlayerId(curTeam?.playerId || "");
      const currentUserRole =
        TeamMemberRole[curTeam?.role || TeamMemberRole.PLAYER];
      setUserRole(currentUserRole);
    }
  }, [isSuccess, teamsQueryData, teamId, userRole, currentTeam]);

  useEffect(() => {
    analytics.page("settings");
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Grid item>
        <Button
          disableRipple
          sx={{ color: "white" }}
          startIcon={<ArrowBackIcon color={"inherit"} />}
          style={{ borderRadius: 0, padding: "1rem" }}
          onClick={() =>
            history.push(getNavigationUrl(teamId, NavigationTabs.DASHBOARD))
          }
        >
          <Typography
            variant="body2"
            style={{
              textTransform: "none",
              paddingTop: "0.5rem",
              color: "white",
            }}
          >
            <Trans>Back to Dashboard</Trans>
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={2}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <Grid item></Grid>
              <Grid item>
                <Grid
                  container
                  id="settingTeamName"
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    <TeamAvatar
                      teamInfo={currentTeam}
                      style={{ width: 40, height: 40, fontSize: 25 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{ textTransform: "uppercase" }}
                    >
                      {currentTeamName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  orientation="vertical"
                  textColor="primary"
                >
                  <Tab
                    label={<Trans>TEAM</Trans>}
                    icon={<AssignmentIcon />}
                    style={{ width: 100 }}
                  />
                  <Tab
                    label={<Trans>CARDS</Trans>}
                    icon={<AccountBoxOutlinedIcon />}
                    style={{ width: 100 }}
                    hidden={userRole === TeamMemberRole.PLAYER}
                  />
                  <Tab
                    label={<Trans>MEMBERS</Trans>}
                    icon={<PeopleOutlineIcon />}
                    style={{ width: 100 }}
                  />
                </Tabs>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={9}>
            <LeftTopPadding>
              <TabPanel value={tab} index={TabsTypes.TEAM}>
                <TeamProfileView playerId={playerId} />
              </TabPanel>
              <TabPanel value={tab} index={TabsTypes.CARDS}>
                <TeamCardsTab {...currentTeam}/>
              </TabPanel>
              <TabPanel value={tab} index={TabsTypes.MEMBERS}>
                <TeamMembersView teamName={currentTeamName} />
              </TabPanel>
            </LeftTopPadding>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ height: "100%" }} xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};
