import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { PlayerTwoStatsLines } from "components/charts/player-two-stats-line";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getTeamEventsWithParticipation,
} from "store/events/events.query";
import { useAuthQuery } from "providers/auth";
import theme from "theme";
import { getPlayersToCompare } from "utils/team";
import { IGetTeamMembersData } from "store/team-members/team-members.model";
import {
  TeamEventUserSpecific,
  TeamEventUserSpecificWithParticipants,
} from "store/events/events.model";
import { StyledDataGrid } from "components/table.component";
import { GridColDef } from "@mui/x-data-grid";
import { IconByEventType } from "containers/timeline/components/event-type.icon";
import {
  EventAttendeesInviteStatus,
  EventAttendeesParticipationStatus,
} from "containers/timeline/event-attendees.tab";
import { PlayerAvatar, PlayerName } from "components/avatar.component";
import { Trans } from "@lingui/macro";

export type FeedbackStatsProps = {
  to: DateTime;
  from: DateTime;
  teamId: string;
  teamMembers?: IGetTeamMembersData;
};
const EVENT_TYPES = ["TRAINING", "MATCH", "OTHER"];
export const FeedbackStats = (props: FeedbackStatsProps) => {
  const { teamId, to, from, teamMembers } = props;
  const history = useHistory();
  const [eventTypes, setEventTypes] = React.useState<string[]>(EVENT_TYPES);
  const ALL_TEAM_MARKER = "ALL_TEAM";
  const [memberId, setMemberId] = React.useState<string>(ALL_TEAM_MARKER);
  const eventsQuery = useAuthQuery(
    ["events", "withParticipation", teamId, from, to],
    getTeamEventsWithParticipation({
      teamId,
      from: from.toMillis() - 1,
      to: to.toMillis() + 1,
    }),
    { enabled: Boolean(teamId && to && from) },
  );
  const onFilterClick = (type: string) => {
    if (eventTypes.includes(type)) {
      setEventTypes(eventTypes.filter((t) => t !== type));
    } else {
      setEventTypes([...eventTypes, type]);
    }
  };
  const [feedbackChartData, setFeedbackChartData] = useState<
    TeamEventUserSpecific[]
  >([]);
  const [missingFeedbacksData, setMissingFeedbacksData] = useState<
    TeamEventUserSpecificWithParticipants[]
  >([]);

  const getTeamMemeber = (playerId: string) => {
    return teamMembers?.teamMembers.find((tm) => tm.playerId === playerId);
  };

  useEffect(() => {
    const filteredTypes =
      eventsQuery.data?.data.filter((e) => eventTypes.includes(e.type)) || [];

    const selectedPlayersOnly = (
      events: TeamEventUserSpecificWithParticipants[],
    ) => {
      return events.map((event) => {
        const personalFeedback =
          event.participants.find((p) => p.playerId === memberId)?.feedback ||
          null;

        return {
          ...event,
          satisfactionAverage: personalFeedback
            ? personalFeedback.satisfactionRating
            : 0,
          intensityAverage: personalFeedback
            ? personalFeedback.intensityRating
            : 0,
        };
      });
    };

    const filteredData =
      memberId && memberId !== ALL_TEAM_MARKER
        ? selectedPlayersOnly(filteredTypes)
        : filteredTypes;

    const skipMissing = filteredData.filter(
      (event) => event.satisfactionAverage && event.intensityAverage,
    );

    setFeedbackChartData(skipMissing);
  }, [eventTypes, eventsQuery.data, memberId]);

  useEffect(() => {
    const filteredTypes =
      eventsQuery.data?.data.filter((e) => eventTypes.includes(e.type)) || [];

    const missingFeedbacks = filteredTypes
      .map((event) =>
        event.participants
          .filter((p) => !p.feedback)
          .filter((p) => getTeamMemeber(p.playerId) && getTeamMemeber(p.playerId)?.role === "PLAYER")
          .map((p) => ({ ...p, ...event, id: p.userEventId }))
          
      )
      .flat()
      .sort((a, b) => b.startTime - a.startTime);

    setMissingFeedbacksData(missingFeedbacks);
  }, [eventTypes, eventsQuery.data, memberId]);

  const missingFeedbackColumns: GridColDef[] = [
    {
      field: "type",
      width: 50,
      headerName: "Event Type",
      renderCell: (params) => <IconByEventType type={params.value} size={40} />,
    },
    {
      field: "title",
      headerName: "Event Title",
      renderCell: (params) => <Button onClick={() => openTheEvent(params.row)}>{params.row.title}</Button>,
      flex: 1,
    },
    {
      field: "playerId",
      headerName: "Player",
      disableColumnMenu: true,
      flex: 1.5,
      sortable: false,
      renderCell: (params) => (
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <PlayerAvatar
              teamId={params.row.teamId}
              playerId={params.value}
              showPosition
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <PlayerName teamId={params.row.teamId} playerId={params.value} />
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "startTime",
      headerName: "Date and Time",
      width: 200,
      valueFormatter: (value) =>
        DateTime.fromMillis(value).toFormat("dd.MM.yyyy HH:mm"),
    },
    {
      field: "inviteStatus",
      headerName: "Invitation",
      renderCell: (params) => (
        <EventAttendeesInviteStatus inviteStatus={params.value} />
      ),
      width: 100,
      align: "center",
    },
    {
      field: "participationStatus",
      headerName: "Data Synced",
      renderCell: (params) => (
        <EventAttendeesParticipationStatus participationStatus={params.value} />
      ),
      width: 100,
      align: "center",
    },
  ];

  const openTheEvent = (event: Pick<TeamEventUserSpecificWithParticipants, "eventId" | "teamId">) => {
    history.push(
      `/teams/${event.teamId}/timeline?eventId=${event.eventId}`,
    );
  };

  return (
    <>
      <Grid container direction="column" alignItems="flex-start" spacing={5}>
        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="start"
            spacing={1}
          >
            <Grid item>
              <FormControl variant="outlined" sx={{ ml: 4, minWidth: "400px" }}>
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    padding: "0 5px",
                    background: theme.background.primary,
                  }}
                  id="selectPlayer-label"
                >
                  <Trans>
                  Select player
                  </Trans>
                </InputLabel>
                <Select
                  labelId="selectPlayer-label"
                  value={memberId}
                  onChange={(_event: any, option: any) => {
                    const v = option.props.value;
                    setMemberId(v);
                  }}
                >
                  <MenuItem
                    key={"team-select-menu-item-all"}
                    value={ALL_TEAM_MARKER}
                    selected={ALL_TEAM_MARKER === memberId}
                  >
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item>All team</Grid>
                    </Grid>
                  </MenuItem>
                  {getPlayersToCompare(
                    memberId,
                    teamMembers?.teamMembers || [],
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item marginLeft={10}>
              <Typography variant="subtitle1">Event types: </Typography>
            </Grid>
            {EVENT_TYPES.map((type) => (
              <Grid item key={type}>
                <Button
                  onClick={() => onFilterClick(type)}
                  key={type}
                  style={{
                    borderRadius: 20,
                    padding: 10,
                    border: eventTypes.includes(type)
                      ? `1px solid ${theme.actions.primary}`
                      : `1px solid ${theme.borders.secondary}`,
                    margin: 3,
                  }}
                >
                  <Typography
                    key={type}
                    variant="subtitle2"
                    color={eventTypes.includes(type) ? "primary" : "grey"}
                  >
                    <Trans>
                    {type}
                    </Trans>
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h3"><Trans>Feedback Chart</Trans></Typography>
        </Grid>
        <Grid item height={300} width={"100%"}>
          {eventsQuery.isLoading && feedbackChartData ? (
            <Skeleton height={350} />
          ) : (
            <PlayerTwoStatsLines
              title={" "}
              useSingleYAxis={true}
              onElementClick={(_dsIndex, index) => {
                const event = eventsQuery.data?.data[index];
                if (event) {
                  openTheEvent(event);
                }
              }}
              data={{
                statOne: {
                  statName: "Intensity",
                  statsMeasure: "POINTS",
                  items: feedbackChartData.map((v) => v.intensityAverage),
                  chartEntryColor: "#5189F6"
                },
                statTwo: {
                  statName: "Satisfaction",
                  statsMeasure: "POINTS",
                  items: feedbackChartData.map((v) => v.satisfactionAverage),
                  chartEntryColor: "#FFB700"
                },
              }}
              labels={feedbackChartData.map(
                (e) =>
                  DateTime.fromMillis(e.startTime).toFormat(
                    "HH:mm dd/MM/yyyy",
                  ) +
                  "\n" +
                  (e.title || e.matchDetails?.opponentTeamName),
              )}
            />
          )}
        </Grid>
        <Grid item>
          <Typography variant="h3"><Trans>Pending feedback</Trans></Typography>
        </Grid>
        <Grid item width="100%">
          {eventsQuery.isLoading ? (
            <Skeleton height={300} />
          ) : (
            <StyledDataGrid
              columns={missingFeedbackColumns}
              rows={missingFeedbacksData}
              autoPageSize
              sx={{
                height: 450,
                width: "95%",
                boreder: "1px solid white",
                border: "1px solid #262626", // Corrected typo here
                borderRadius: "4px", // Added border radius
                "& .MuiDataGrid-columnHeaderTitle": {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1,
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
