import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { InviteStatus, TeamEventUserSpecific } from "store/events/events.model";
import theme from "theme";
import { Trans } from "@lingui/macro";
import { DateTime } from "luxon";
import { TabPanel } from "components/tabs.component";
import { useAuthQuery } from "providers/auth";
import { getTeamEventById } from "store/events/events.query";
import { TeamMemberRole } from "store/team-members/team-members.model";
import { UpdateTimelineEventModal } from "./modals/event-update.modal";
import { EventAttendeesTab } from "./event-attendees.tab";
import { IconByEventType } from "./components/event-type.icon";
import { NavigationContext } from "providers/navigation.provider";
import TagSignalIcon from "components/icons/TagSignalIcon";
import { EventLeaderBoardTab } from "./event-leaderboard.tab";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { EventDescriptionTab } from "./event-details.tab";
import { TimelineEventFeedbackTab } from "./event-feedback.tab";
import { getTeamMembers } from "store/team-members/team-members.query";
import { EventUpdateConfirm } from "./modals/event-update-confirm.modal";
import "./modals/event-timeline.css";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import { EventRemove } from "./modals/event-remove.modal";
import { EventPlayerCards } from "./event-player-cards";

type EventDescriptionProps = {
  event: TeamEventUserSpecific;
  eventId: string;
  didEventUpdate: (eventUpdated: boolean) => void;
};

enum TabsTypes {
  "OVERVIEW",
  "LEADERBOARD",
  "CARDS",
  "FEEDBACK",
  "ATTENDEES",
  "VIDEO",
  "SUMMARY",
  "ANALYTICS",
}

export const EventView = (props: EventDescriptionProps) => {
  const history = useHistory();
  const analytics = useAnalytics();
  const { currentTeam, selectedLocale } = React.useContext(NavigationContext);
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", props.event.teamId],
    getTeamMembers(props.event.teamId),
  );
  const eventQuery = useAuthQuery(
    ["event", props.event.eventId],
    getTeamEventById(props.event.eventId),
  );
  const getTranslatedEventTabs = (tab: TabsTypes) => {
    switch (tab) {
      case TabsTypes.OVERVIEW:
        return <Trans id="timeline_event_overview_tab">OVERVIEW</Trans>;
      case TabsTypes.LEADERBOARD:
        return <Trans id="timeline_event_leaderboard_tab">LEADERBOARD</Trans>;
      case TabsTypes.CARDS:
          return <Trans id="timeline_event_cards_tab">CARDS</Trans>;
      case TabsTypes.ATTENDEES:
        return <Trans id="timeline_event_attendees_tab">ATTENDEES</Trans>;
      case TabsTypes.VIDEO:
        return <Trans id="timeline_event_video_tab">VIDEO</Trans>;
      case TabsTypes.SUMMARY:
        return <Trans id="timeline_event_summary_tab">SUMMARY</Trans>;
      case TabsTypes.FEEDBACK:
        return <Trans id="timeline_event_feedback_tab">FEEDBACK</Trans>;
      case TabsTypes.ANALYTICS:
        return (
          <Trans id="timeline_event_analytics_tab">
            <Grid container alignItems="center">
              <Grid item>ANALYTICS</Grid>
              <Grid item>&nbsp;</Grid>
              <Grid item>
                <OpenInNewIcon />
              </Grid>
            </Grid>
          </Trans>
        );
    }
  };

  const [tab, setTab] = React.useState(TabsTypes.OVERVIEW);
  const [removeModalOpen, setRemoveModalOpen] = React.useState(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [updateConfirmModalOpen, setUpdateConfirmModalOpen] =
    React.useState(false);

  const onlyPlayersIds = teamMembersQuery.data?.teamMembers
    .filter((member) => member.role === TeamMemberRole.PLAYER)
    .map((member) => member.playerId);

  const setEventTab = (tab: TabsTypes) => {
    analytics.track(`event-tab-${String(tab).toLowerCase()}`);
    setTab(tab);
  };

  if (eventQuery.isLoading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "25%" }}
      >
        <Grid item>
          <CircularProgress
            color="secondary"
            style={{ width: 100, height: 100 }}
          />
        </Grid>
        <Grid item>
          <Trans id="timeline_loading_event">Loading event...</Trans>
        </Grid>
      </Grid>
    );
  }

  if (!eventQuery.data) {
    return (
      <Box>
        <Trans id="timeline_event_no_data">No data</Trans>
      </Box>
    );
  }

  const event = eventQuery.data;
  const acceptedParticipants = event.participants.filter(
    (participant) => participant.inviteStatus === InviteStatus.ACCEPTED,
  );
  const playersAcceptedCount = acceptedParticipants.filter(
    (participant) =>
      onlyPlayersIds && onlyPlayersIds?.indexOf(participant.playerId) > -1,
  ).length;
  const start = DateTime.fromMillis(event.startTime);
  const end = DateTime.fromMillis(event.endTime);
  const canEdit = currentTeam.role !== TeamMemberRole.PLAYER;

  return (
    <Grid item>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item id="title">
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Box
                    style={{
                      color: "black",
                      fontSize: 0,
                      borderRadius: 1,
                      padding: 5,
                      width: 40,
                      height: 40,
                    }}
                  >
                    <IconByEventType type={props.event.type} size={35} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{event.title}</Typography>
                </Grid>

                {canEdit ? (
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        event.seriesId
                          ? setUpdateConfirmModalOpen(!updateConfirmModalOpen)
                          : setUpdateModalOpen(!updateModalOpen)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => setRemoveModalOpen(true)}>
                      <DeleteIcon />
                    </IconButton>
                    {removeModalOpen ? (
                      <EventRemove
                        event={event}
                        onClose={() => {
                          setRemoveModalOpen(false);
                        }}
                        open={removeModalOpen}
                      />
                    ) : (
                      ""
                    )}
                    {event.seriesId ? (
                      <EventUpdateConfirm
                        open={updateConfirmModalOpen}
                        event={event}
                        onClose={(eventUpdated) => {
                          setUpdateConfirmModalOpen(false);
                          analytics.track("event-updated-series");
                          eventUpdated && props.didEventUpdate(true);
                        }}
                      />
                    ) : (
                      <UpdateTimelineEventModal
                        open={updateModalOpen}
                        {...event}
                        onClose={(eventUpdated) => {
                          setUpdateModalOpen(false);
                          analytics.track("event-updated");
                          eventUpdated && props.didEventUpdate(true);
                        }}
                        isUpdatingSeries={false}
                      />
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            id="info"
            direction="row"
            alignItems="center"
            spacing={2}
            style={{
              color: theme.text.subtitle,
            }}
          >
            <Grid item id="autoEvent" hidden={!event.autoEventId}>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <TagSignalIcon
                    fill={theme.text.primary}
                    style={{
                      paddingTop: 5,
                      paddingRight: 2,
                      transform: "rotate(-67deg)",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  >
                    <Trans id="timeline_auto_event_label">Auto Event</Trans>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="time">
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <CalendarTodayOutlinedIcon
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  >
                    {start.day}. {start.setLocale(selectedLocale).monthLong}{" "}
                    {start.year} {start.toFormat("HH:mm")} -{" "}
                    {end.toFormat("HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="location">
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <LocationOnOutlinedIcon
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  >
                    {event.locationName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="attendance">
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <PeopleAltOutlinedIcon
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 13, lineHeight: "13px" }}
                  >
                    <Trans>{playersAcceptedCount} participated</Trans>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {event.seriesId && (
            <Grid item pl={2.5}>
              <Typography className="label__repeating-event">
                <Trans id="repeating_event_text">Repeating Event</Trans>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Tabs
            value={tab}
            onChange={(_e, value) => setEventTab(value)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={getTranslatedEventTabs(TabsTypes.OVERVIEW)}
              style={{ fontSize: 14 }}
            />
            <Tab
              label={getTranslatedEventTabs(TabsTypes.LEADERBOARD)}
              style={{ fontSize: 14 }}
            />
            <Tab
              label={getTranslatedEventTabs(TabsTypes.CARDS)}
              style={{ fontSize: 14 }}
            />
            <Tab
              label={getTranslatedEventTabs(TabsTypes.FEEDBACK)}
              style={{ fontSize: 14 }}
            />
            <Tab
              label={getTranslatedEventTabs(TabsTypes.ATTENDEES)}
              style={{ fontSize: 14 }}
            />
            {event.endTime < DateTime.now().toMillis() && (
              <Tab
                label={getTranslatedEventTabs(TabsTypes.ANALYTICS)}
                style={{ fontSize: 14 }}
                onClick={() => {
                  analytics.track("event-tab-analytics");
                  history.push(
                    `/teams/${props.event.teamId}/analytics?eventId=${event.eventId}`,
                  );
                }}
              />
            )}
          </Tabs>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item>
          <TabPanel value={tab} index={TabsTypes.OVERVIEW}>
            <EventDescriptionTab
              setUpdateConfirmModalOpen={setUpdateConfirmModalOpen}
              setUpdateModalOpen={setUpdateModalOpen}
              role={currentTeam.role}
              {...event}
            />
          </TabPanel>
          <TabPanel value={tab} index={TabsTypes.LEADERBOARD}>
            <EventLeaderBoardTab {...event} />
          </TabPanel>
          <TabPanel value={tab} index={TabsTypes.CARDS}>
            <EventPlayerCards eventId={event.eventId} startTime={event.startTime} />
          </TabPanel>
          <TabPanel value={tab} index={TabsTypes.ATTENDEES}>
            <EventAttendeesTab {...event} />
          </TabPanel>
          <TabPanel value={tab} index={TabsTypes.FEEDBACK}>
            <TimelineEventFeedbackTab {...event} />
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};
